module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Soundcon","short_name":"Soundcon","start_url":"/","background_color":"#fff","theme_color":"#3C7ABE","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e51b645291620be253ba9a9fdf2f8c5d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","css","map","png"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://soundcon.se"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":17,"matomoUrl":"https://matomo.accodeing.com","siteUrl":"https://soundcon.se"},
    },{
      plugin: require('../node_modules/@accodeing/gatsby-theme-heimr/gatsby-browser.js'),
      options: {"plugins":[],"manifest":{"name":"Soundcon","short_name":"Soundcon","background_color":"#fff","theme_color":"#3C7ABE"},"siteUrl":"https://soundcon.se","matomoSiteId":17},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
